@font-face {
  font-family: 'Haffer Trial';
  src: url('https://haffer-trial-font.s3.us-east-2.amazonaws.com/Haffer-TRIAL-Thin.woff') format('woff');
  font-weight: 100;
}
@font-face {
  font-family: 'Haffer Trial';
  src: url('https://haffer-trial-font.s3.us-east-2.amazonaws.com/Haffer-TRIAL-Light.woff') format('woff');
  font-weight: 300;
}
@font-face {
  font-family: 'Haffer Trial';
  src: url('https://haffer-trial-font.s3.us-east-2.amazonaws.com/Haffer-TRIAL-Regular.woff') format('woff');
  font-weight: 400;
}
@font-face {
  font-family: 'Haffer Trial';
  src: url('https://haffer-trial-font.s3.us-east-2.amazonaws.com/Haffer-TRIAL-Medium.woff') format('woff');
  font-weight: 500;
}
@font-face {
  font-family: 'Haffer Trial';
  src: url('https://haffer-trial-font.s3.us-east-2.amazonaws.com/Haffer-TRIAL-SemiBold.woff') format('woff');
  font-weight: 600;
}
@font-face {
  font-family: 'Haffer Trial';
  src: url('https://haffer-trial-font.s3.us-east-2.amazonaws.com/Haffer-TRIAL-Bold.woff') format('woff');
  font-weight: 700;
}
@font-face {
  font-family: 'Haffer Trial';
  src: url('https://haffer-trial-font.s3.us-east-2.amazonaws.com/Haffer-TRIAL-Heavy.woff') format('woff');
  font-weight: 800;
}
@font-face {
  font-family: 'Haffer Trial';
  src: url('https://haffer-trial-font.s3.us-east-2.amazonaws.com/Haffer-TRIAL-Black.woff') format('woff');
  font-weight: 900;
} 